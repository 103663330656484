<template>
  <base-section
    id="features"
    class="primary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards[$i18n.locale]"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              color="white"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionBenefits',

    data: () => ({
      cards: {
        en: [
          {
            icon: 'mdi-folder-multiple',
            text: 'Collect and analyse sustainability data about your supply chain in a cost-effective way.',
          },
          {
            icon: 'mdi-monitor-eye',
            text: 'Get a clear overview and awareness of sustainability risks as well as needed corrective actions.',
          },
          {
            icon: 'mdi-account-heart',
            text: 'Enjoy a single easy-to-use platform for everyone – both your staff and your suppliers.',
          },
          {
            icon: 'mdi-puzzle-outline',
            text: 'Always be up-to-date as we continuously improve and extend the platform to support your sustainability work.',
          },
        ],
        sv: [
          {
            icon: 'mdi-folder-multiple',
            text: 'Samla in och analysera information om leverantörskedjan på ett enkelt och kostnadseffektivt sätt.',
          },
          {
            icon: 'mdi-monitor-eye',
            text: 'Få en god överblick och insikt om befintliga risker, liksom om var åtgärder behöver vidtas.',
          },
          {
            icon: 'mdi-account-heart',
            text: 'Jobba i en enda smidig plattform som är enkel att använda för alla – för dig, dina medarbetare och dina leverantörer.',
          },
          {
            icon: 'mdi-puzzle-outline',
            text: 'Håll dig uppdaterad och ta del av våra ständiga förbättringar som förenklar ditt arbete.',
          },
        ],
      }
    }),
  }
</script>
